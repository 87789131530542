import type { GetAccountInvitationsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountInvitationsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'store/types'
import { actions } from './slice'
import { getAccountInvitationsSelector } from 'store/modules/accountInvitations/selectors'

export const { setAccountInvitations } = actions

export const getAccountInvitations =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountInvitationsRequest = {
      type: 'GetAccountInvitationsRequest',
    }

    gateway
      .emit<GetAccountInvitationsResponse>(data)
      .then((body) => {
        dispatch(setAccountInvitations(body))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const getAccountInvitationsIfRequired = (): TypedThunk => (dispatch, getState) => {
  const { invitations } = getAccountInvitationsSelector(getState())

  if (invitations) {
    return
  }

  dispatch(getAccountInvitations())
}
