import { redirect, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { strStartsWith } from '@patrianna/shared-utils/helpers'
import ROUTES from 'temp/routes.json'
import useOtpConfirmRequired from './useOtpConfirmRequired'

const allowedPages = [ROUTES.SWEEPSTAKE_RULES, ROUTES.TERMS, ROUTES.RESPONSIBLE_PLAY, ROUTES.PRIVACY, ROUTES.OTP]

export default function useOtpRedirect() {
  const pathname = usePathname()
  const isAllowedPage = allowedPages.some((route) => strStartsWith(route, pathname))
  const isOtpConfirmRequired = useOtpConfirmRequired()
  const isOtpRedirect = !isAllowedPage && isOtpConfirmRequired

  useEffect(() => {
    if (isOtpRedirect) {
      redirect(ROUTES.OTP)
    }
  }, [isOtpRedirect])
}
