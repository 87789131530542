'use client'

import { GraphyteScript } from '@patrianna-payments/analytics'
import { ClarityScript } from '@patrianna-payments/analytics/clarity/script'
import { graphytePageView } from '@patrianna-payments/analytics/graphyte/events'
import AnalyticsScripts from '@patrianna-payments/analytics/main/core/Scripts'
import Optimizely from '@patrianna-payments/analytics/optimizely/script'
import SaveWebReferrerScript from '@patrianna-payments/analytics/save-web-referrer/script'
import { skipOtpSelectorWithMandatoryAndClosed } from '@patrianna-payments/shared-store/skip-otp'
import { usePageViewTracker } from '@patrianna/shared-hooks'
import { getCookie } from '@patrianna/shared-utils'
import { getUserOS } from '@patrianna/shared-utils/getUserOS'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { configureScope } from '@sentry/nextjs'
import { useAuth } from 'app/context/auth'
import { useRouter } from 'app/context/navigation'
import { legalRoutes } from 'config/legalRoutes'
import Chats from 'containers/Chats'
import GoogleAuthOneTap from 'containers/GoogleAuthOneTap'
import RemoteEntryPaymentGlobal from 'containers/RemoteEntry/global'
import SaveWebReferrer from 'containers/SaveWebReferrer'
import SessionDialogsProvider from 'containers/SessionDialogsProvider'
import TurnOffSpinnerInButtons from 'containers/TurnOffSpinnerInButtons'
import VisibilityProvider from 'containers/VisibilityProvider'
import RootDialog from 'dialogs/components/RootDialog'
import { useAnalyticSubscriptions } from 'hooks/mf/useAnalyticSubscription'
import { useAuthCountriesMF } from 'hooks/mf/useAuthCountriesMF'
import { useAuthStatesMF } from 'hooks/mf/useAuthStatesMF'
import { useRouterSubscriptions } from 'hooks/mf/useRouterSubscriptions'
import { useSentrySubscriptions } from 'hooks/mf/useSentryMf'
import useAppInit from 'hooks/useAppInit'
import useOtpRedirect from 'hooks/useOtpRedirect'
import useRequireKYCIdentityVerification from 'hooks/useRequireKYCIdentityVerification'
import useSeonInit from 'hooks/useSeonInit'
import useSocialRegistration from 'hooks/useSocialRegistration'
import { usePathname } from 'next/navigation'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import { useAppSelector } from 'store/hooks'
import { getGraphyteEnabledSelector, needToVerifyEmailSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

export function RootLayoutClient({ children }: { children: ReactNode }) {
  const router = useRouter()
  const pathname = usePathname()
  const emailNotVerified = useAppSelector(needToVerifyEmailSelector)
  const { isLoggedIn } = useAuth()
  const skipOptWithClosedAndMandatory = useAppSelector(skipOtpSelectorWithMandatoryAndClosed)

  const graphyteEnabled = useAppSelector(getGraphyteEnabledSelector)

  const isAnalyticsAvailable = process.env.PROFILE === 'prod' || process.env.PROFILE === 'dev'

  useAppInit()
  useOtpRedirect()
  useRequireKYCIdentityVerification()
  useSeonInit()
  useSocialRegistration()
  // MF start
  useRouterSubscriptions()
  useAnalyticSubscriptions()
  useAuthStatesMF()
  useAuthCountriesMF()
  useSentrySubscriptions()
  // MF end

  usePageViewTracker(() => {
    if (graphyteEnabled) {
      graphytePageView()
    }
  }, [graphyteEnabled])

  useEffect(() => {
    if (emailNotVerified && !legalRoutes.some((route: string) => pathname.includes(route))) {
      router.push(ROUTES.CONFIRM_EMAIL)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [emailNotVerified, pathname])

  useEffect(() => {
    const userId = getCookie(`${process.env.BRAND_NAME.toUpperCase()}AID`, document.cookie)

    if (userId) {
      configureScope((scope) => scope.setUser({ id: userId }))
    }

    document.body.classList.add(getUserOS())
  }, [])

  return (
    <section id='root-layout'>
      <RemoteEntryPaymentGlobal />
      <GoogleAuthOneTap />
      <SaveWebReferrer />
      <TurnOffSpinnerInButtons />
      {isLoggedIn && <VisibilityProvider />}
      {children}
      <RootDialog />

      {skipOptWithClosedAndMandatory && !emailNotVerified && isLoggedIn && <SessionDialogsProvider />}

      {isAnalyticsAvailable && (
        <>
          <AnalyticsScripts />
          <GraphyteScript profile={process.env.GRAPHYTE_API_KEY} brandKey={process.env.GRAPHYTE_BRAND_KEY} />
          <ClarityScript profile={process.env.CLARITY} />
          <Optimizely url={process.env.OPTIMIZELY_URL} />
        </>
      )}
      {isBrowser() && <Chats />}
      <SaveWebReferrerScript rootUrl={process.env.ROOT_URL} />
      <GlobalSnackbar />
    </section>
  )
}
