import type { AnyAction, CombinedState, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import { verificationsMiddleware } from 'store/middlewares/verificationsMiddleware'
import { paymentsMiddleware } from 'store/middlewares/paymentsMiddleware'
import { redeemMiddleware } from 'store/middlewares/redeemMiddleware'
import { authMiddleware } from './middlewares/authMiddleware'
import rootReducer from './modules/rootReducer'
import type { ServiceDependenciesOptions } from 'src/services'
import { serviceDependencies } from 'src/services'
import './channels'
import { pubSubManager } from 'services/pubsub_con'
import type { PubSubDataChannel } from '@patrianna-payments/pub-sub/src/lib/core'

const defaultReduxMiddlewareOptions = {
  thunk: {
    extraArgument: serviceDependencies,
  },
}

const listenerMi = createListenerMiddleware()

const channelCache: Record<string, PubSubDataChannel<unknown>> = {}

listenerMi.startListening({
  predicate: (action) =>
    action.type.includes('user') || action.type.includes('appConfig') || action.type.includes('currencies'),
  effect: (action, listenerApi) => {
    const [reducerName] = action?.type?.split('/')

    let channel = channelCache[reducerName]

    if (!channel) {
      channel = pubSubManager.createDataChannel(reducerName, null)
      channelCache[reducerName] = channel
      channel.open()
    }

    const store: Record<string, unknown> = listenerApi.getState() as Record<string, unknown>

    channel.update(store[reducerName])
  },
})

const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      /*
        With this configuration, we can use "serviceDependencies" in each thunk and access to our API via third argument

        Example:
        const getAccountInfo = () => (dispatch, getState, { gateway }) => {
          gateway.emit<GetAccountInfoRequest>({ type: 'GetAccountInfoRequest' })
        }

        Docs:
        https://redux-toolkit.js.org/api/getDefaultMiddleware
      */
      getDefaultMiddleware(defaultReduxMiddlewareOptions).concat([
        listenerMi.middleware,
        verificationsMiddleware,
        redeemMiddleware,
        authMiddleware,
        paymentsMiddleware,
      ]),
    devTools: process.env.PROFILE === 'dev',
  })
}

const store = makeStore()

type CleanState<T> = T extends CombinedState<infer S> ? { [K in keyof S]: CleanState<S[K]> } : T

type AppDispatch = typeof store.dispatch
type ReduxState = CleanState<ReturnType<typeof rootReducer>>
type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>
type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, ReduxState, ServiceDependenciesOptions, AnyAction>

export type { AppDispatch, ReduxState, TypedDispatch, TypedThunk, ServiceDependenciesOptions }

export default store
