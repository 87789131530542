import { usePathname } from 'next/navigation'
import { useMemo } from 'react'
import { useAppSelector } from 'src/store/hooks'
import { getLocationDataSelector, isSCVisibleSelector } from 'store/modules/appConfig/selectors'
import {
  getLiveChatPurchaseFlowEnabledSelector,
  getUserFirstPurchaseSelector,
  getUserRegisteredCountrySelector,
  isLoggedInSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

const ALLOWED_COUNTRY = 'US'
const RESTRICTED_ROUTES = [ROUTES.IDENTITY_VERIFICATION, ROUTES.CONFIRM_EMAIL]

export const SNIPPET_KEY = process.env.ZENDESK_SNIPPET_KEY
export const APP_ID = process.env.ZENDESK_APP_ID
export const BLOCKED_STATES = process.env.ZENDESK_BLOCKED_STATES
export const CHAT_BOT_ID = process.env.CHAT_BOT_ID
const KEY_ID = process.env.ZENDESK_AUTH_KEY_ID

export default function useShowChats(): {
  isShowLiveChat: boolean
  isShowLiveChatFromMenu: boolean
  isShowChatBot: boolean
  isShowChatBotCom: boolean
} {
  const pathname = usePathname()
  const loggedIn = useAppSelector(isLoggedInSelector)
  const scVisible = useAppSelector(isSCVisibleSelector)
  const location = useAppSelector(getLocationDataSelector)
  const firstPurchase = useAppSelector(getUserFirstPurchaseSelector)
  const country = useAppSelector(getUserRegisteredCountrySelector)
  const liveChatPurchaseFlow = useAppSelector(getLiveChatPurchaseFlowEnabledSelector)
  // const liveChatSettings = useAppSelector(getLiveChatSettingsSelector) // [FYI]: need for future

  const isGamePage = pathname?.includes('/play') && !pathname?.includes('/play-')
  const isLoginAndCountryAllowed = loggedIn && country === ALLOWED_COUNTRY
  const blockedStates = typeof BLOCKED_STATES === 'string' ? BLOCKED_STATES.split(',') : (BLOCKED_STATES as string[])

  const isRouteRestricted = useMemo((): boolean => RESTRICTED_ROUTES.includes(pathname), [pathname])

  // [FYI]: [firstPurchase === undefined] need for waiting response from GetPaymentMetaInfoResponse
  if (isGamePage || !isLoginAndCountryAllowed || firstPurchase === undefined || isRouteRestricted) {
    return {
      isShowLiveChat: false,
      isShowLiveChatFromMenu: false,
      isShowChatBot: false,
      isShowChatBotCom: false,
    }
  }

  const isExistZendeskKeys = Boolean(APP_ID && SNIPPET_KEY)
  const isShowChatBots = !scVisible && !blockedStates.find((el) => el === location?.state)
  const isLiveChatEnabled = isLoginAndCountryAllowed && scVisible && !!firstPurchase && isExistZendeskKeys // [FYI]: general logic for Live chat
  // const isLiveChatEnabled = isLoginAndCountryAllowed && isExistZendeskKeys && liveChatSettings?.showLiveChat // [FYI]: general logic for Live chat with BE data
  const isLiveChatOnPurchaseEnabled =
    isLoginAndCountryAllowed && scVisible && isExistZendeskKeys && liveChatPurchaseFlow // [FYI]: general logic for Live chat on payment flow with BE data

  return {
    isShowLiveChat: isLiveChatOnPurchaseEnabled && Boolean(KEY_ID),
    isShowLiveChatFromMenu: isLiveChatEnabled && Boolean(KEY_ID),
    isShowChatBot: isShowChatBots && isExistZendeskKeys,
    isShowChatBotCom: isShowChatBots && Boolean(CHAT_BOT_ID),
  }
}
