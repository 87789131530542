import ROUTES from 'temp/routes.json'
import { BRAND_MCLUCK } from 'config/brands'

export const legalRoutes = [
  ROUTES.TERMS,
  ROUTES.PRIVACY,
  ROUTES.RESPONSIBLE_PLAY,
  ...(BRAND_MCLUCK
    ? // @ts-ignore issues with type we have those routes only for mcluck
      [ROUTES.TERMS_CONDITIONS, ROUTES.PRIVACY_CALIFORNIA]
    : []),
]
