import { skipOtpClosedSelector, skipOtpMandatorySelector } from '@patrianna-payments/shared-store/skip-otp'
import { useAppSelector } from 'src/store/hooks'
import { showOtpPageSelector } from 'store/modules/user/selectors'

export default function useOtpConfirmRequired() {
  const showOtpPage = useAppSelector(showOtpPageSelector)
  const closedOtpPage = useAppSelector(skipOtpClosedSelector)
  const isOtpMandatory = useAppSelector(skipOtpMandatorySelector)
  const isClosed = closedOtpPage && !isOtpMandatory

  return showOtpPage && !isClosed
}
