'use client'

import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { NavigationProvider } from 'app/context/navigation'
import store from 'src/store'
import { TranslationsProvider } from 'app/context/translations'
import { AuthProvider } from 'app/context/auth'
import type { AuthCookies } from '@patrianna/shared-utils/getAuthCookies'
import { CountryConfigProvider } from 'app/context/country'
import { DetectMobileProvider } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { decode } from '@patrianna/core-components/src/context/translations/utils/encryption'

type Props = {
  children: ReactNode
  translations: any
  authCookies: AuthCookies
  isMobile: boolean
  countryCode: string
  locationCountryCode: string | null
  prismicDynamicValuesConfig: Record<string, string>
}

export function Providers({
  children,
  translations,
  authCookies,
  isMobile,
  countryCode,
  locationCountryCode,
  prismicDynamicValuesConfig,
}: Props) {
  useEffect(() => {
    const decodedTranslation = decode(translations)
    // TODO: temporary solution for handling translation keys within validator utility. Need to rework this.
    // @ts-ignore
    window.__FORM_VALIDATION_TRANSLATIONS__ = {
      formValidators: decodedTranslation?.formValidators,
      verification: decodedTranslation?.pageIdentityVerification,
    }
  }, [translations])

  return (
    <DetectMobileProvider isMobile={isMobile}>
      <Provider store={store}>
        <CountryConfigProvider countryCode={countryCode} locationCountryCode={locationCountryCode}>
          <TranslationsProvider
            translations={translations}
            prismicDynamicValuesConfig={prismicDynamicValuesConfig}
            shouldDecodeTranslations
          >
            <AuthProvider authCookies={authCookies}>
              <NavigationProvider>{children}</NavigationProvider>
            </AuthProvider>
          </TranslationsProvider>
        </CountryConfigProvider>
      </Provider>
    </DetectMobileProvider>
  )
}
