import { useSubscribeAction } from 'services/pubsub_con'
import { trackEvent } from 'config/analytic'
import { AnalyticChannel, AnalyticEvent } from '@patrianna-payments/mf-channels'

export const useAnalyticSubscriptions = () => {
  useSubscribeAction<Record<string, unknown>>(AnalyticChannel.Actions, AnalyticEvent.Track, (payload, options) => {
    const { event, ...data } = payload

    // @ts-ignore
    void trackEvent(event as string, data, options)
  })
}
