import type { GetAccountFreeSpinsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountFreeSpinsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'store/types'
import { removeFromHistorySessionDialogs, waitingForSessionDialogsData } from '../sessionDialogs/actions'
import { actions } from './slice'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { getHistorySessionDialogsSelector } from 'store/modules/sessionDialogs/selectors'
import { openDialog } from 'store/modules/dialog/actions'
import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/SlotGameFlowModule'

export const { saveFreeSpins } = actions

export const getUserFreeSpins =
  (isScEnabled: boolean): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountFreeSpinsRequest = {
      type: 'GetAccountFreeSpinsRequest',
      currency: isScEnabled ? ['SC', 'GC'] : ['GC'],
    }

    gateway
      .emit<GetAccountFreeSpinsResponse>(data)
      .then((respBody) => {
        dispatch(saveFreeSpins({ freeSpins: respBody?.data }))
        dispatch(waitingForSessionDialogsData({ freeSpins: true }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const showFSModalFlow =
  (freeSpins: FreeSpinType[]): TypedThunk =>
  (dispatch, getState) => {
    const dialogStack = getDialogStackSelector(getState())
    const historySessionDialogs = getHistorySessionDialogsSelector(getState())
    const isFSSessionWasShown = historySessionDialogs?.includes('FREE_SPINS_DIALOG')

    if (dialogStack.length && isFSSessionWasShown) {
      dispatch(removeFromHistorySessionDialogs({ modalName: 'FREE_SPINS_DIALOG' }))
    }
    // field .fromWS will be added to packages after successful testing
    // @ts-ignore
    freeSpins[0].fromWS = !dialogStack.length
    dispatch(saveFreeSpins({ freeSpins }))

    if (!dialogStack.length) {
      dispatch(openDialog({ modalName: 'FREE_SPINS_DIALOG', dialogProps: { freeSpins } }))
    }
  }
