import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import dialogs from 'src/dialogs'
import type { DialogNames } from 'store/types'
import { useAppDispatch } from 'src/store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { parseDialogParamsFromQuery } from '@patrianna/shared-utils'

// const parseDialogParams = (dialogProps: any) => {
//   // parse query string
//   if (!dialogProps || !Object.keys(dialogProps)?.length) {
//     return null
//   }

//   return Object.keys(dialogProps).reduce((acc: { [key: string]: string }, key) => {
//     if (key.includes('dialogProps')) {
//       const parsedKey = key.split('.')[1]
//       acc[parsedKey] = dialogProps[key]

//       return acc
//     }
//     acc[key] = dialogProps[key]

//     return acc
//   }, {})
// }

export default function useOpenDialogByQuery() {
  const query = useSearchParams()
  const pathname = usePathname()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const { dialogName, ...dialogProps } = parseDialogParamsFromQuery(query)

    if (dialogName && Object.prototype.hasOwnProperty.call(dialogs, dialogName.toString())) {
      window.history.replaceState({}, document.title, pathname)
      dispatch(openDialog({ modalName: dialogName as DialogNames, dialogProps: dialogProps }))
    }
  }, [pathname, query])
}
