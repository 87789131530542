import { useEffect, useState } from 'react'
import { seonInit, setSeonSessionIdToDB } from 'services/seon'
import { useAuth } from 'app/context/auth'
import { yieldToMain } from '@patrianna/shared-utils'

export default function useSeonInit() {
  const [isLoading, setLoading] = useState<boolean>(true)
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    void seonInit().finally(() => setLoading(false)) // [FYI]: load seon script
  }, [])

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      yieldToMain().then(setSeonSessionIdToDB) // [FYI]: checking session storage for existing seon token and sending to BE
    }
  }, [isLoggedIn, isLoading])
}
