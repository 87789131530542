import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { HabaneroJackpot, HabaneroJackpotState } from './types'

const initialState: HabaneroJackpotState = {
  jackpots: [],
}

export const habaneroJackpotSlice = createSlice({
  name: 'habaneroJackpot',
  initialState,
  reducers: {
    saveHabaneroJackpots: (state, action: PayloadAction<{ jackpots: Array<HabaneroJackpot> }>) => {
      state.jackpots = action.payload.jackpots
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof habaneroJackpotSlice.actions>>
export const { actions } = habaneroJackpotSlice
