import type {
  SentryCaptureExceptionType,
  SentryCaptureMessageType,
} from '@patrianna-payments/shared-store/sentry.types'
import * as Sentry from '@sentry/nextjs'

import type { TypedThunk } from 'src/store'

export const handleSentryCaptureMessage =
  ({ tags, message, breadcrumbs = null }: SentryCaptureMessageType): TypedThunk =>
  () => {
    Sentry.withScope((scope) => {
      Object.entries(tags).map(([key, value]) => scope.setTag(key, value))
      breadcrumbs?.map((breadcrumb) => scope.addBreadcrumb(breadcrumb))
      Sentry.captureMessage(message, scope)
    })
  }

export const handleSentryCaptureException =
  ({ tags, exception, breadcrumbs = null }: SentryCaptureExceptionType): TypedThunk =>
  () => {
    Sentry.withScope((scope) => {
      Object.entries(tags).map(([key, value]) => scope.setTag(key, value))
      breadcrumbs?.map((breadcrumb) => scope.addBreadcrumb(breadcrumb))
      Sentry.captureException(exception, scope)
    })
  }
