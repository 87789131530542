import { useAppSelector } from 'store/hooks'
import { isIgnoreAlwaysOpenDailyBonusSelector } from 'store/modules/bonusesFlow/selectors'

import type { SessionDialogIgnoreAlwaysOpenFlags } from './types'

export const useSessionDialogIgnoreAlwaysOpenFlags = (): SessionDialogIgnoreAlwaysOpenFlags => {
  const isIgnoreAlwaysOpenDailyBonus = useAppSelector(isIgnoreAlwaysOpenDailyBonusSelector)

  return {
    isIgnoreAlwaysOpenDailyBonus,
  }
}
