import cx from 'classnames'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import { Snackbar } from '@patrianna/core-components'
import { useReturnToCashier } from 'src/hooks/useReturnToCashier'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'
import {
  getPositionHorizontalSelector,
  getPositionVerticalSelector,
  getSnackbarActionButtonSelector,
  getSnackbarAutoHideSelector,
  getSnackbarCloseWhenPathChangedSelector,
  getSnackbarComponentPropsSelector,
  getSnackbarMessageAlignSelector,
  getSnackbarMessageSelector,
  getSnackbarVariantSelector,
  getSnackbarVisibilitySelector,
} from 'store/modules/snackbar/selectors'
import SnackbarContent from './SnackbarContent'
import classes from './styles.module.scss'

function GlobalSnackbar() {
  const pathname = usePathname()
  const isSnackbarVisible = useAppSelector(getSnackbarVisibilitySelector)
  const message = useAppSelector(getSnackbarMessageSelector)
  const variant = useAppSelector(getSnackbarVariantSelector)
  const actionButton = useAppSelector(getSnackbarActionButtonSelector)
  const autoHide = useAppSelector(getSnackbarAutoHideSelector)
  const messageAlign = useAppSelector(getSnackbarMessageAlignSelector)
  const messageProps = useAppSelector(getSnackbarComponentPropsSelector)
  const positionVertical = useAppSelector(getPositionVerticalSelector)
  const positionHorizontal = useAppSelector(getPositionHorizontalSelector)
  const closeWhenPathChanged = useAppSelector(getSnackbarCloseWhenPathChangedSelector)
  const dispatch = useAppDispatch()
  const CustomComponent = message
  const isMessageString = typeof message === 'string'
  const snackbarAction = actionButton?.action
  const handleRepeatPayment = useReturnToCashier()

  const handleClose = () => {
    if (messageProps?.offerCode) {
      handleRepeatPayment(`${messageProps?.offerCode}`)
    } else if (actionButton?.action && typeof actionButton.action === 'function') {
      actionButton.action()
    }
    dispatch(closeSnackbar())
  }

  useEffect(() => {
    if (closeWhenPathChanged) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [pathname, closeWhenPathChanged])

  if (!CustomComponent) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: positionVertical || 'bottom',
        horizontal: positionHorizontal || 'center',
      }}
      open={isSnackbarVisible}
      onClose={handleClose}
      autoHideDuration={autoHide || 4000}
      className={cx({ [classes.modalLikeSnackbar]: isMessageString || messageProps?.isModalLike })}
      disableAnchoring={isMessageString || !!messageProps?.isModalLike}
    >
      {isMessageString ? (
        // @ts-ignore
        <SnackbarContent
          variant={variant}
          message={message}
          action={snackbarAction as () => void}
          messageAlign={messageAlign}
        />
      ) : (
        // @ts-ignore
        <CustomComponent {...messageProps} />
      )}
    </Snackbar>
  )
}

export default GlobalSnackbar
