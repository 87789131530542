import type { WSRequestsBody } from '@patrianna/shared-patrianna-types/websocket/requests'
import { initSocketListeners } from 'store/middlewares/initSocketListenersMiddleware'

export type InstanceWSProps = {
  connect: (dispatch: Function) => Promise<unknown>
  disconnect: () => void
  getInstance: () => null | WebSocket
  emitWS: (event: WSRequestsBody) => () => void
  clear: () => void
}

export const WSinstance = ((): InstanceWSProps => {
  let socketRoot: null | WebSocket = null

  const emitWS = (event: WSRequestsBody) => () => {
    console.log('emitWS is deprecated, move request to REST API', event)
  }
  const clear = () => {
    socketRoot = null
  }

  const connect = (dispatch: Function): Promise<unknown> => {
    return new Promise((resolve) => {
      const platform = 'web'
      const SERVER_URL = `${process.env.WEBSOCKET}/ws?brandName=${process.env.BRAND_NAME}&platform=${platform}`

      socketRoot = new WebSocket(SERVER_URL)
      // @ts-ignore
      window.soc = socketRoot // test

      socketRoot.onopen = () => {
        dispatch(initSocketListeners())
        resolve(true)
      }
    })
  }

  const disconnect = () => {
    // eslint-disable-next-line
    console.info('ws disconnect')
    socketRoot?.close(4444)

    socketRoot = null
  }

  return {
    getInstance() {
      return socketRoot
    },
    connect,
    disconnect,
    emitWS,
    clear,
  }
})()

const managementWS = {
  cancel() {
    clearTimeout(this.connectingTimer)
    this.connectingTimer = null
  },

  disconnect() {
    if (typeof this.connectingTimer === 'number') {
      this.cancel()
    }

    this.connectingTimer = setTimeout(() => {
      WSinstance.disconnect()
      this.cancel()
    }, 5000)
  },

  connect(dispatch: Function): Promise<unknown> | null {
    if (typeof this.connectingTimer === 'number') {
      this.cancel()

      return null
    }

    const socket = WSinstance.getInstance()

    if (!socket || WebSocket.CLOSED === socket?.readyState) {
      WSinstance.connect(dispatch)
    }

    return null
  },
}

export const appStateInactiveHandler = () => () => {
  const socket = WSinstance.getInstance()

  if (socket) {
    managementWS.disconnect()
  }
}

export const appStateActiveHandler = () => (dispatch: Function) => {
  managementWS.connect(dispatch)
}
