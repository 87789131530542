import { createSelector } from '@reduxjs/toolkit'
import { getLocationDataSelector } from 'store/modules/appConfig/selectors'
import { SoftKycStateSelector, jumioKycStateSelector } from 'store/modules/user/selectors'

export const getPrizeoutStateSelector = createSelector(
  getLocationDataSelector,
  SoftKycStateSelector,
  jumioKycStateSelector,
  (location, softKycState, KYCstate) => KYCstate || softKycState || location?.state
)
