import { useAuthStates } from 'hooks/useAuthStates'
import { useEffect } from 'react'
import { useAction, useSubscribeAction } from 'services/pubsub_con'
import { AuthEvent, AuthChannel } from '@patrianna-payments/mf-channels'
import type { SignUpProvinceAndState } from '@patrianna/shared-utils/countryStates/types'

// todo make the same approach as in useAuthCountriesMF
export const useAuthStatesMF = () => {
  const { provincesAndStates } = useAuthStates()
  const authAction = useAction<AuthEvent, SignUpProvinceAndState>(AuthChannel.Data)

  useSubscribeAction(AuthChannel.Data, AuthEvent.RequestStates, () => {
    authAction(AuthEvent.UpdateStates, provincesAndStates)
  })

  useEffect(() => {
    authAction(AuthEvent.UpdateStates, provincesAndStates)
  }, [authAction, provincesAndStates])
}
