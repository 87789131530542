import { serviceDependencies } from 'src/services'
import type { CountryCode } from './countryCodes'
import { arrayFilterNullable } from 'utils/filterNullable'

export async function getSignUpCountries() {
  try {
    const result = await serviceDependencies.gateway.emit<{ countries: CountryCode[] }>({
      type: 'GetSignupCountriesRequest',
    })

    return arrayFilterNullable(result?.countries)
  } catch (error) {
    return []
  }
}
