import { type ChangeEvent, useEffect, useState } from 'react'
import { getSignUpCountries } from 'src/utils/getSignupCountries'
import { transformCountries, countryCodes, type SignUpCountry, type CountryCode } from 'utils/countryCodes'
import { useTranslation } from 'app/context/translations'

export const useAuthCountries = (currentCountry: CountryCode) => {
  const t = useTranslation()
  const initialState = [{ value: '' as CountryCode, name: t('authPages.select_country') }]
  const initialCurrentCountry: CountryCode | '' = countryCodes.includes(currentCountry) ? currentCountry : ''
  const [countries, setCountries] = useState<SignUpCountry[]>(initialState)
  const [activeCountry, setActiveCountry] = useState<CountryCode | ''>(initialCurrentCountry)

  const onChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    setActiveCountry(e.target.value as CountryCode)
  }

  useEffect(() => {
    if (countries.some((country) => country.value === activeCountry)) {
      setActiveCountry(activeCountry)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [countries])

  useEffect(() => {
    if (process.env.MULTI_COUNTRY_SUPPORT) {
      getSignUpCountries().then((data) => {
        setCountries(transformCountries(data, t))
      })
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  return { countries, activeCountry, onChangeCountry }
}
