import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import type { DialogNames } from 'store/types'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { closeLatestDialog, openDialog } from 'store/modules/dialog/actions'
import { removeFromHistorySessionDialogs, setHistorySessionDialogs } from 'store/modules/sessionDialogs/actions'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { actionsMap } from './actionsMap'
import dialogConfig from 'temp/dialogsConfig.json'
import type { SessionDialog } from './types'
import { useSessionDialogFlags } from './useSessionDialogFlags'
import { checkRouteList, getNextSessionDialog } from './utils'
import { useSessionDialogIgnoreAlwaysOpenFlags } from 'containers/SessionDialogsProvider/useSessionDialogIgnoreAlwaysOpenFlags'

type Props = {
  visibleSessionDialog: DialogNames
  historySessionDialogs: DialogNames[]
}

export default function OpenSessionDialogs({ visibleSessionDialog, historySessionDialogs }: Props): null {
  const dispatch = useAppDispatch()
  const pathname = usePathname()
  const sweepstakeEnabled = useAppSelector(sweepstakeEnabledSelector)
  const currency = sweepstakeEnabled ? 'SC' : 'GC'
  const flags = useSessionDialogFlags()
  const ignoreAlwaysOpenFlags = useSessionDialogIgnoreAlwaysOpenFlags()
  const dialogs: SessionDialog[] = dialogConfig as unknown as SessionDialog[]

  // If current open dialog is session dialog and open on ignore route
  // when remove it from store and close
  const closeDialogInIgnoreRoute = () => {
    if (visibleSessionDialog) {
      const sessionDialog = dialogs.find((dialog) => dialog.modalName === visibleSessionDialog)
      const isIgnoreRoute = checkRouteList(sessionDialog?.ignoreRoutes, pathname)

      if (isIgnoreRoute) {
        dispatch(removeFromHistorySessionDialogs({ modalName: visibleSessionDialog }))
        dispatch(closeLatestDialog())
      }
    }
  }

  // Remove session dialog from history if dialog should always be open and while the flag is true
  const removeFromHistoryIfNeedOpen = () => {
    historySessionDialogs.forEach((modalName) => {
      const removeDialog = dialogs.find((dialog: SessionDialog) => dialog.modalName === modalName && dialog.alwaysOpen)

      if (removeDialog && flags[removeDialog.flag] && !ignoreAlwaysOpenFlags[removeDialog.ignoreAlwaysOpenFlag]) {
        dispatch(removeFromHistorySessionDialogs({ modalName }))
      }
    })
  }

  const handleOpenDialogs = () => {
    // get next session dialog by platform and currency and flag
    const sessionDialog = getNextSessionDialog(
      dialogs,
      {
        currency,
        platform: 'WEB',
        flags,
      },
      historySessionDialogs,
      pathname
    )

    if (!sessionDialog) {
      return
    }

    const { modalName, action } = sessionDialog

    // Open next dialog and added to history session dialogs
    if (!visibleSessionDialog) {
      dispatch(action ? actionsMap[action as keyof typeof actionsMap]() : openDialog({ modalName }))
      dispatch(setHistorySessionDialogs({ modalName }))
    }
  }

  useEffect(() => {
    closeDialogInIgnoreRoute()
    handleOpenDialogs()
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [visibleSessionDialog, pathname, historySessionDialogs])

  useEffect(() => {
    removeFromHistoryIfNeedOpen()
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [pathname])

  return null
}
