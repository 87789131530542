import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useAppDispatch } from 'store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { SOCIAL_REGISTRATION, GOOGLE_REGISTRATION_VALUES, FB_REGISTRATION_VALUES } from 'config/sessionStorage'
import ROUTES from 'temp/routes.json'

export default function useSocialRegistration() {
  const pathname = usePathname()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const savedValues = sessionStorage.getItem(SOCIAL_REGISTRATION)
    const socialRegistrationData = savedValues ? JSON.parse(savedValues) : null

    const isGoogleRegistrationNotFinished = Boolean(sessionStorage.getItem(GOOGLE_REGISTRATION_VALUES))
    const isFBRegistrationNotFinished = Boolean(sessionStorage.getItem(FB_REGISTRATION_VALUES))
    const isTermsPage = pathname?.includes(ROUTES.TERMS)

    if (socialRegistrationData && (isGoogleRegistrationNotFinished || isFBRegistrationNotFinished) && !isTermsPage) {
      dispatch(openDialog({ modalName: 'AUTH_CONFIRM_DIALOG', dialogProps: socialRegistrationData }))
    }
  }, [pathname])
}
